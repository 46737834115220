import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './GamePage.scss';
import CharGrid from '../CharGrid/CharGrid';
import chars from '../../constants/chars';

export default class GamePage extends Component {
  static propTypes = {
    gameType: PropTypes.string.isRequired,
    onQuitToMainMenu: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      question: undefined,
      answer: undefined,
      wrongAnswer: undefined,
      numWrong: 0,
      numRight: 0,
    };
  }

  componentDidMount() {
    this.nextQuestion();
  }

  onSelectChar(charId) {
    const { answer, numRight, numWrong } = this.state;
    console.log('>>', answer, charId);
    if (answer === charId) {
      this.nextQuestion();
      this.setState({
        wrongAnswer: undefined,
        numRight: numRight + 1,
      });
    } else {
      this.setState({
        wrongAnswer: charId,
        numWrong: numWrong + 1,
      });
    }
  }

  onShowAnswer() {
    console.log('todo');
  }

  nextQuestion() {
    const { gameType } = this.props;
    let next, nextCell;

    while (!next) {
      nextCell = this.pickRandomCell();
      if (nextCell) {
        switch (gameType) {
          default:
          case 'roma':
            [, next] = nextCell;
            break;
          case 'hira':
            [next] = nextCell;
            break;
          case 'kata':
            [next] = nextCell;
            break;
        }
      }
    }

    this.setState({
      question: next,
      answer: nextCell[0],
    });
  }

  pickRandomCell() {
    const row = parseInt(Math.random() * chars.length, 10),
      cell = parseInt(Math.random() * chars[0].length, 10);
    return chars[row] && chars[row][cell] && chars[row][cell];
  }

  render() {
    const { question, wrongAnswer, numWrong, numRight } = this.state;
    const { gameType, onQuitToMainMenu } = this.props;
    return (
      <div className="GamePage">
        <header>
          <button className="backBtn" onClick={onQuitToMainMenu}>
            &lt; Back
          </button>
          <div className={`question ${gameType === 'roma' && 'jpn'}`} dangerouslySetInnerHTML={{ __html: question }} />
          <div className="counts">
            <div className="numWrong">{numWrong}</div>
            <div className="numRight">{numRight}</div>
          </div>
          {/* <button className="btn" onClick={() => this.onShowAnswer()}>
            Hint
          </button> */}
        </header>
        <CharGrid type={gameType} onSelectChar={charId => this.onSelectChar(charId)} wrongAnswer={wrongAnswer} />
      </div>
    );
  }
}
