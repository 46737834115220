import React, { Component } from 'react';
import PropTypes from 'prop-types';

import chars from '../../constants/chars';
import './CharGrid.scss';

export default class CharGrid extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    onSelectChar: PropTypes.func,
    wrongAnswer: PropTypes.string,
  };

  static defaultProps = {
    wrongAnswer: undefined,
    onSelectChar: undefined,
  };

  onSelectChar(charId) {
    const { onSelectChar } = this.props;
    onSelectChar && onSelectChar(charId);
  }

  render() {
    const { type } = this.props;
    return <div className="CharGrid">{this.renderRows(chars, type)}</div>;
  }

  renderRows(rows, type) {
    return rows.map((row, id) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="row" key={`row${id}${row}`}>
        {this.renderCells(row, type)}
      </div>
    ));
  }

  renderCells(cells, type) {
    const { wrongAnswer } = this.props;

    return cells.map((cell, i) => {
      if (cell) {
        const id = cell[0];
        const highlightClass = wrongAnswer === id ? 'cell-highlight' : '';

        const [roma, hira, kata] = cell;
        let value;
        switch (type) {
          default:
          case 'roma':
            value = `<span class="eng">${roma}</span>`;
            break;
          case 'hira':
            value = hira;
            break;
          case 'kata':
            value = kata;
            break;
          case 'ref':
            value = `${hira} <sub class="eng">${roma}</sub>`;
            break;
        }

        return (
          <button
            className={`cell ${highlightClass}`}
            key={id}
            dangerouslySetInnerHTML={{ __html: value }}
            onClick={() => this.onSelectChar(id)}
          />
        );
      }

      // we have nothing else to index by
      // eslint-disable-next-line react/no-array-index-key
      return <div className="cell" key={i} />;
    });
  }
}
