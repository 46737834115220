// romanji, hiragana, katakana
export default [
  [
    ['a', '&#x3042;', '&#x30A2;'],
    ['i', '&#x3044;', '&#x30A4;'],
    ['u', '&#x3046;', '&#x30A6;'],
    ['e', '&#x3048;', '&#x30A8;'],
    ['o', '&#x304A;', '&#x30AA;'],
  ],
  [
    ['ka', '&#x304B;', '&#x30AB;'],
    ['ki', '&#x304D;', '&#x30AD;'],
    ['ku', '&#x304F;', '&#x30AF;'],
    ['ke', '&#x3051;', '&#x30B1;'],
    ['ko', '&#x3053;', '&#x30B3;'],
  ],
  [
    ['sa', '&#x3055;', '&#x30B5;'],
    ['shi', '&#x3057;', '&#x30B7;'],
    ['su', '&#x3059;', '&#x30B9;'],
    ['se', '&#x305B;', '&#x30BB;'],
    ['so', '&#x305D;', '&#x30BD;'],
  ],
  [
    ['ta', '&#x305F;', '&#x30BF;'],
    ['chi', '&#x3061;', '&#x30C1;'],
    ['tsu', '&#x3064;', '&#x30C4;'],
    ['te', '&#x3066;', '&#x30C6;'],
    ['to', '&#x3068;', '&#x30C8;'],
  ],
  [
    ['na', '&#x306A;', '&#x30CA;'],
    ['ni', '&#x306B;', '&#x30CB;'],
    ['nu', '&#x306C;', '&#x30CC;'],
    ['ne', '&#x306D;', '&#x30CD;'],
    ['no', '&#x306E;', '&#x30CE;'],
  ],
  [
    ['ha', '&#x306F;', '&#x30CF;'],
    ['hi', '&#x3072;', '&#x30D2;'],
    ['hu', '&#x3075;', '&#x30D5;'],
    ['he', '&#x3078;', '&#x30D8;'],
    ['ho', '&#x307B;', '&#x30DB;'],
  ],
  [
    ['ma', '&#x307E;', '&#x30DE;'],
    ['mi', '&#x307F;', '&#x30DF;'],
    ['mu', '&#x3080;', '&#x30E0;'],
    ['me', '&#x3081;', '&#x30E1;'],
    ['mo', '&#x3082;', '&#x30E2;'],
  ],
  [['ya', '&#x3084;', '&#x30E4;'], null, ['yu', '&#x3086;', '&#x30E6;'], null, ['yo', '&#x3088;', '&#x30E8;']],
  [
    ['ra', '&#x3089;', '&#x30E9;'],
    ['ri', '&#x308A;', '&#x30EA;'],
    ['ru', '&#x308B;', '&#x30EB;'],
    ['re', '&#x308C;', '&#x30EC;'],
    ['ro', '&#x308D;', '&#x30ED;'],
  ],
  [
    ['wa', '&#x308F;', '&#x30EF;'],
    ['wi', '&#x3090;', '&#x30F0;'],
    null,
    ['we', '&#x3091;', '&#x30F1;'],
    ['wo', '&#x3092;', '&#x30F2;'],
  ],
  [['n', ' &#x3093', '&#x30F3;'], null, null, null, null],
];
