import React, { Component } from 'react';
import './App.scss';
import GamePage from '../GamePage/GamePage';
import MenuPage from '../MenuPage/MenuPage';
import RefPage from '../RefPage/RefPage';

class App extends Component {
  constructor() {
    super();
    this.state = {
      page: 'menu',
    };
  }

  onStartGame = gameType => {
    this.setState({ page: gameType });
  };

  onQuitToMainMenu = () => {
    this.setState({ page: 'menu' });
  };

  renderPage() {
    const { page } = this.state;

    switch (page) {
      case 'hira':
      case 'kata':
      case 'roma':
        return <GamePage onQuitToMainMenu={this.onQuitToMainMenu} gameType={page} />;

      case 'reference':
        return <RefPage onQuitToMainMenu={this.onQuitToMainMenu} />;

      default:
      case 'menu':
        return <MenuPage onStartGame={this.onStartGame} />;
    }
  }

  render() {
    return <main className="App">{this.renderPage()}</main>;
  }
}

export default App;
