import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './MenuPage.scss';

export default class MenuPage extends Component {
  static propTypes = {
    onStartGame: PropTypes.func.isRequired,
  };

  render() {
    const { onStartGame } = this.props;

    return (
      <div className="MenuPage">
        <header>
          <h1 className="title">Goju-on Game</h1>
          <span className="desc">A game to aid learning of hiragana & katana</span>
        </header>

        <button className="gameBtn" onClick={() => onStartGame('hira')}>
          Hiragana
        </button>
        <button className="gameBtn" onClick={() => onStartGame('kata')}>
          Katakana
        </button>
        <button className="gameBtn" onClick={() => onStartGame('roma')}>
          Romaji
        </button>
        <hr />
        <button className="gameBtn" onClick={() => onStartGame('reference')}>
          Reference
        </button>
      </div>
    );
  }
}
