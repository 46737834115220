import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './RefPage.scss';
import CharGrid from '../CharGrid/CharGrid';

export default class RefPage extends Component {
  static propTypes = {
    onQuitToMainMenu: PropTypes.func.isRequired,
  };

  render() {
    const { onQuitToMainMenu } = this.props;
    return (
      <div className="RefPage">
        <header>
          <button className="btn" onClick={onQuitToMainMenu}>
            &lt; Back
          </button>
        </header>
        <CharGrid type="ref" />
      </div>
    );
  }
}
